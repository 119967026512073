import React from 'react'
import { Link } from 'gatsby'
import { Button, Box, Heading, Text } from 'theme-ui'

/**
 * Shadow me to add your own content
 */

const styles = {
  author: {
    display: `inline-block`,
    color: `alpha`
  },
  occupation: {
    fontSize: 100,
    mb: 0
  },
  specialty: {
    color: `text`,
    mb: 4
  }
}

// Hero 컨텐트 수정
// 버튼 숨기기
export default () => (
  <>
    <Heading variant='h1' sx={styles.occupation}>
      개발자 지망생
    </Heading>
    <Heading variant='h1'>
      <Text sx={styles.author}>Aio</Text>입니다.
    </Heading>
    {/* <Heading variant='h1' sx={styles.occupation}>
      Student
    </Heading> */}
    <Heading variant='h2' sx={styles.specialty}>
      JS 중심으로 프론트, 백엔드를 고루고루 공부하고 있습니다.
    </Heading>
    {/* <Box variant='buttons.group'>
      <Button as={Link} to='/contact'>
        Contact Me
      </Button>
      <Button variant='white' as={Link} to='/about'>
        About Me
      </Button>
    </Box> */}
  </>
)
